<template>
  <section class="container-fluid p-0">
    <div class="about-block d-flex flex-wrap align-items-center element-background p-0">
      <figure class="mb-0 col-12 col-md-6">
        <img
          alt="About image"
          src="~/assets/images/about.png"
          class="w-100 h-100 about-block__image"
          v-if="!switchRoute"
        >
        <img
          alt="About image"
          src="~/assets/images/about-silver.png"
          class="w-100 h-100 about-block__image"
          v-else
        >
      </figure>
      <div
        class="mb-0 col-12 col-md-6 h-100 text-center d-flex align-items-center justify-content-center description-wrapper"
      >
        <div class="px-md-2">
          <h2 class="about-block__title">
            {{ $t('main.about_us.title') }}
          </h2>

          <!-- <tooltip :enterable="true" class="width-100" :content="$t('main.about_us.descriptions')"> -->
          <p class="about-block__description">
            {{ $t('main.about_us.descriptions') }}
          </p>
          <!-- </tooltip> -->
          <nuxt-link
            :to="{
              path: localePath(`/about-gold-center`)
            }"
            tag="a"
            class="btn shadow-none rounded-0 element-color element-border-without-radius d-inline-flex align-items-center justify-content-center text-uppercase text-decoration-none"
          >
            {{ $t('main.about_us.btn_text') }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'CardSmallSellWithUs',
  computed: {
    switchRoute () {
      return this.$store.state.siteMode.sitemode === 'silver'
    }
  }
}
</script>
<style scoped>
.about-block {
  margin-top: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.description-wrapper {
  padding-left: 50px;
  padding-right: 50px;
}
.description-wrapper h2, .description-wrapper p {
  color: #000;
}

.description-wrapper a {
  max-width: 153px;
  height: 50px;
  background: #fff;
}
</style>
